.profileinfosection,
.profilepage {
  display: flex;
  flex-direction: column;
}
.profileinfosection {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
  border: 1px solid var(--color-whitesmoke-200);
  padding: var(--padding-9xl) var(--padding-281xl);
  align-items: center;
  justify-content: center;
}
.profilepage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 431px) {
  .profileinfosection {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
