.eventtitle {
  align-self: stretch;
  position: relative;
  line-height: 54px;
  font-weight: 600;
}
.posteddate {
  position: relative;
  font-size: var(--font-size-5xl);
  line-height: 40px;
  font-weight: 500;
}
.eventtitlepostdate {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.eventmainimageIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  height: auto;
}
.b {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
}
.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.am {
  margin: 0;
}
.time {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.zelle6785708663 {
  position: relative;
  line-height: 34px;
  color: var(--color-gray-200);
  white-space: pre-wrap;
  text-align: right;
}
.location1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
}
.acec5453WillContainer {
  position: relative;
  line-height: 34px;
  color: var(--color-gray-200);
  text-align: right;
}
.eventcontentpage,
.eventtimelocation {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.eventtimelocation {
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
  padding: var(--padding-11xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
}
.eventcontentpage {
  border-radius: var(--br-5xs);
  background-color: var(--color-aliceblue-200);
  overflow: hidden;
  padding: 0 var(--padding-81xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.event1detailpage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-30xl);
  color: var(--primary-and-header-color);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 960px) {
  .eventtimelocation {
    padding: var(--padding-5xl) 0;
    box-sizing: border-box;
  }
  .eventcontentpage {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .eventmainimageIcon {
    align-items: center;
    justify-content: center;
    height: auto;
  }
}
@media screen and (max-width: 431px) {
  .eventtitle {
    font-size: var(--font-size-11xl);
  }
  .posteddate {
    font-size: var(--font-size-xl);
  }
  .eventmainimageIcon {
    object-fit: contain;
  }
  .acec5453WillContainer,
  .b,
  .zelle6785708663 {
    font-size: var(--font-size-sm);
  }
  .eventcontentpage,
  .eventtimelocation {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .eventcontentpage {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
  }
}
