.discoverTheWorld {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
.discoverTheWorld1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-20xl);
  line-height: 40px;
  font-family: var(--font-changa-one);
  color: var(--color-darkslategray-100);
}
.pasteventtitlesection,
.recCardsContainer {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.pasteventtitlesection {
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.recCardsContainer {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-xl);
  margin-left: -16px;
  margin-right: -16px;
}
.pasteventpage,
.pasteventsection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.pasteventsection {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  padding: var(--padding-3xs) var(--padding-11xl) var(--padding-21xl);
  align-items: center;
  gap: 48px;
}
.pasteventpage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  align-items: flex-start;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-blueviolet);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 1200px) {
  .pasteventsection {
    padding: var(--padding-21xl) var(--padding-61xl) var(--padding-41xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .recCardsContainer {
    margin-left: -16px;
    margin-right: -16px;
    flex-wrap: wrap;
  }
  .pasteventsection {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .discoverTheWorld1 {
    font-size: var(--font-size-17xl);
  }
  .pasteventsection {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 431px) {
  .recCardsContainer {
    flex-direction: column;
  }
}
