.b,
.dec {
  position: relative;
}
.b {
  align-self: stretch;
  line-height: 44px;
}
.dec {
  font-size: var(--font-size-5xl);
  line-height: 18px;
}
.am,
.divinfoDateBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.am {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 18px;
  color: var(--color-gray-300);
  width: 68px;
}
.divinfoDateBox {
  background-color: var(--color-gray-100);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-smi);
}
.icon {
  position: relative;
  width: 165px;
  height: 123.3px;
  object-fit: cover;
}
.atlanta2024International {
  position: relative;
  line-height: 42px;
  font-weight: 600;
}
.divcontentRight,
.eventcard {
  display: flex;
  justify-content: flex-start;
}
.divcontentRight {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--font-size-16xl);
}
.eventcard {
  border-radius: var(--br-8xs);
  background-color: var(--color-aliceblue-200);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-11xl) var(--padding-31xl);
  gap: var(--gap-3xs);
  cursor: pointer;
}
.b2,
.eventcard,
.eventcard1 {
  align-self: stretch;
}
.eventcard1 {
  border-radius: var(--br-8xs);
  background-color: var(--color-aliceblue-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xl) var(--padding-31xl);
  gap: var(--gap-3xs);
  cursor: pointer;
}
.b2 {
  position: relative;
  line-height: 44px;
  height: 30px;
  flex-shrink: 0;
}
.b2,
.divinfoDateBox2,
.pm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pm {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 18px;
  color: var(--color-gray-300);
  width: 68px;
  height: 30px;
  flex-shrink: 0;
}
.divinfoDateBox2 {
  background-color: var(--color-gray-400);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-smi);
}
.eventcard2 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-aliceblue-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-11xl) var(--padding-31xl);
  gap: var(--gap-3xs);
  cursor: pointer;
}
.eventcard2,
.eventcard4,
.eventframe,
.eventpage {
  display: flex;
  justify-content: flex-start;
}
.eventcard4 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-aliceblue-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-11xl) var(--padding-31xl);
  gap: var(--gap-3xs);
}
.eventframe,
.eventpage {
  background-color: var(--primary-contrast);
  flex-direction: column;
  align-items: flex-start;
}
.eventframe {
  align-self: stretch;
  border-radius: var(--br-5xs);
  padding: 0 var(--padding-xl);
  gap: var(--gap-8xs);
}
.eventpage {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-darkslateblue);
  font-family: var(--font-source-sans-pro);
}
@media screen and (max-width: 420px) {
  .divcontentRight {
    flex: unset;
    align-self: stretch;
  }
  .eventcard,
  .eventcard1,
  .eventcard2,
  .eventcard4 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 431px) {
  .divcontentRight {
    flex: unset;
    align-self: stretch;
  }
  .eventcard {
    flex-direction: column;
  }
  .eventcard1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .eventcard1,
  .eventcard2,
  .eventcard4 {
    flex-direction: column;
  }
}
