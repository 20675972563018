.eventtitle {
  align-self: stretch;
  position: relative;
  line-height: 54px;
  font-weight: 600;
}
.posteddate {
  position: relative;
  font-size: var(--font-size-5xl);
  line-height: 40px;
  font-weight: 500;
}
.eventtitlepostdate {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.shutterstock3814610111Icon {
  position: relative;
  width: 1240px;
  height: auto;
  object-fit: cover;
}
.atlanta2024International {
  align-self: stretch;
  position: relative;
  line-height: 36px;
}
.acecAndAsian {
  margin: 0;
}
.acecAndAsianContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--components-button-large);
  color: var(--color-slategray-100);
}
.eventcontent,
.eventcontentpage {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.eventcontent {
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  gap: var(--gap-7xs);
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-changa-one);
}
.eventcontentpage {
  border-radius: var(--br-5xs);
  background-color: var(--color-aliceblue-200);
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-81xl);
  gap: var(--gap-3xs);
  cursor: pointer;
}
.eventdetail4page {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-30xl);
  color: var(--primary-and-header-color);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 960px) {
  .eventcontentpage {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 431px) {
  .eventtitle {
    font-size: var(--font-size-11xl);
  }
  .shutterstock3814610111Icon {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .eventcontentpage {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
    height: auto;
  }
}
