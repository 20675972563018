.enterYourPhone {
  align-self: stretch;
  position: relative;
  line-height: 34px;
  font-weight: 600;
}
.loginmessage {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.button,
.input {
  font-family: var(--components-button-large);
}
.input {
  border: 1px solid var(--studio-lightmode-midseparator-dee3eb);
  font-size: var(--font-size-xl);
  background-color: var(--primary-contrast);
  align-self: stretch;
  border-radius: var(--br-9xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  justify-content: flex-start;
  line-height: 30px;
  outline: 0;
}
.button {
  position: relative;
  font-size: var(--components-button-large-size);
  letter-spacing: 0.46px;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--primary-contrast);
  text-align: left;
}
.submit,
.unstyledbutton {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.unstyledbutton {
  padding: var(--padding-5xs) 22px;
  align-items: flex-start;
  justify-content: flex-start;
}
.submit {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #f99a0e;
  border-radius: var(--br-9xs);
  height: 56px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.submit:hover {
  background-color: #dc880b;
}
.loginframe {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--primary-contrast);
  padding: var(--padding-xl) var(--padding-181xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mid);
}
.loginform,
.loginframe,
.loginpage {
  display: flex;
  flex-direction: column;
}
.loginform {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
  border: 1px solid var(--color-whitesmoke-200);
  padding: var(--padding-9xl) var(--padding-281xl);
  align-items: center;
  justify-content: center;
}
.loginpage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 420px) {
  .submit {
    width: 100%;
  }
  .submit:hover {
    background-color: #9e6104;
  }
}
@media screen and (max-width: 431px) {
  .enterYourPhone {
    font-size: var(--font-size-sm);
  }
  .submit {
    height: 40px;
  }
  .loginframe {
    padding: var(--padding-3xs) var(--padding-3xs) var(--padding-xl);
    box-sizing: border-box;
  }
  .loginform {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
