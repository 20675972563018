.storyImageIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.aboutUs {
  position: relative;
  line-height: 36px;
}
.theAtlantaChinese {
  margin: 0;
}
.theAtlantaChineseContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--components-button-large);
  color: var(--color-slategray-100);
}
.missionsection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.missionsectionParent {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-21xl) 0 0;
  gap: var(--gap-xl);
}
.aboutuspage,
.aboutussection,
.missionsectionParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutussection {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-aliceblue-200);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-2xs);
  gap: 40px;
}
.aboutuspage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-changa-one);
}
@media screen and (max-width: 420px) {
  .storyImageIcon {
    width: 100% !important;
    height: auto !important;
  }
  .missionsectionParent,
  .storyImageIcon {
    flex: unset;
    align-self: stretch;
  }
  .aboutussection {
    flex-direction: column;
  }
}
@media screen and (max-width: 431px) {
  .missionsectionParent,
  .storyImageIcon {
    flex: unset;
    align-self: stretch;
  }
  .aboutussection {
    flex-direction: column;
  }
}
