.cardImage1,
.chineseNewYear {
  align-self: stretch;
  position: relative;
}
.cardImage1 {
  border-radius: 11.84px;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
}
.chineseNewYear {
  cursor: pointer;
  font-weight: 500;
}
.onFebruary4th {
  margin: 0;
}
.cardDescription1Container {
  cursor: pointer;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  color: var(--studio-darkmode-normalsolid-icons-96a2be);
}
.cardDetails1,
.pasteventcard {
  background-color: var(--primary-contrast);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardDetails1 {
  align-self: stretch;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-mini);
}
.pasteventcard {
  flex: 1;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--studio-darkmode-popuplabels-5e6a86);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 1200px) {
  .pasteventcard {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .chineseNewYear {
    font-size: var(--font-size-11xl);
  }
  .cardDescription1Container {
    font-size: var(--font-size-3xl);
  }
}
@media screen and (max-width: 431px) {
  .chineseNewYear {
    font-size: var(--font-size-xl);
  }
  .cardDescription1Container {
    font-size: var(--font-size-sm);
  }
  .pasteventcard {
    flex: unset;
    align-self: stretch;
  }
}
