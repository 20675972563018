.acec {
  position: relative;
  display: inline-block;
  width: 184px;
  flex-shrink: 0;
}
.copyrightInformation {
  cursor: pointer;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  font-family: var(--components-button-large);
  color: var(--color-lightslategray);
  text-align: left;
}
.leftframe {
  flex: 1;
  background-color: var(--primary-contrast);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}
.contactUs {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--font-size-sm);
  font-family: var(--components-button-large);
  color: var(--color-lightslategray);
  text-align: center;
  display: inline-block;
}
.contactUsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.nBerkeleyLake {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  font-family: var(--components-button-large);
  color: var(--color-lightslategray);
  text-align: left;
  display: inline-block;
}
.address {
  flex: 1;
  background-color: var(--primary-contrast);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.frameChild {
  position: relative;
  width: 22px;
  height: 22px;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.email,
.footer,
.rightframe {
  background-color: var(--primary-contrast);
  display: flex;
  justify-content: flex-start;
}
.email {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.footer,
.rightframe {
  flex-direction: row;
  align-items: center;
}
.rightframe {
  flex: 1;
}
.footer {
  align-self: stretch;
  padding: var(--padding-5xl) 0;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-rowdies);
}
@media screen and (max-width: 1200px) {
  .copyrightInformation {
    display: none;
  }
  .footer {
    padding: var(--padding-11xl) var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .copyrightInformation {
    text-align: center;
    display: none;
  }
  .footer {
    padding: var(--padding-10xl) var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .copyrightInformation {
    display: none;
  }
}
@media screen and (max-width: 431px) {
  .copyrightInformation {
    display: none;
  }
  .address,
  .email,
  .leftframe {
    flex: unset;
    align-self: stretch;
  }
  .footer,
  .rightframe {
    flex-direction: column;
  }
  .rightframe {
    gap: var(--gap-0);
    flex: unset;
    align-self: stretch;
  }
  .footer {
    gap: var(--gap-31xl);
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
}
