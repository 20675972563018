.acectitle {
  cursor: pointer;
  align-self: stretch;
  position: relative;
}
.theAtlantaChinese {
  margin: 0;
}
.acecdesc {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 26px;
  font-family: var(--components-button-large);
  color: var(--color-slategray-100);
}
.featuredDestinationCopywriti {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.cityimageIcon {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  height: 480px;
  flex-shrink: 0;
  object-fit: cover;
}
.herocityimage,
.herosectionnew {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.herocityimage {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-21xl) 0 0;
  box-sizing: border-box;
  height: auto;
}
.herosectionnew {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-21xl);
  gap: var(--gap-11xl);
}
.youtubeviewer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 480px;
}
.featuredDestinationTitle {
  cursor: pointer;
  align-self: stretch;
  position: relative;
  line-height: 36px;
}
.featuredDestinationCopywriti1,
.videosection,
.youtubedescription {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.videosection,
.youtubedescription {
  padding: var(--padding-11xl);
  align-items: flex-start;
}
.videosection {
  background-color: #f5f5f5;
  padding: var(--padding-41xl) var(--padding-11xl);
  gap: var(--gap-31xl);
  text-align: center;
  font-size: var(--font-size-20xl);
  color: var(--color-darkslategray-100);
}
.cardImage1,
.chineseNewYear {
  align-self: stretch;
  position: relative;
}
.cardImage1 {
  border-radius: 11.84px;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
}
.chineseNewYear {
  cursor: pointer;
  font-weight: 800;
}
.cardDescription1Container {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 500;
  color: var(--studio-darkmode-normalsolid-icons-96a2be);
}
.card1,
.cardDetails1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardDetails1 {
  align-self: stretch;
  gap: 7.39px;
}
.card1 {
  flex: 1;
  gap: 28.64px;
}
.cardDescription2Container {
  text-decoration: none;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 500;
  color: var(--studio-darkmode-normalsolid-icons-96a2be);
}
.cardImage21 {
  align-self: stretch;
  position: relative;
  border-radius: 11.84px;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}
.cardDetails3,
.mainsection {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.cardDetails3 {
  flex-direction: column;
  justify-content: flex-start;
  gap: 9.24px;
}
.mainsection {
  background-color: var(--color-aliceblue-200);
  box-shadow: 0 0 46.9px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-31xl) var(--padding-41xl);
  justify-content: center;
  gap: var(--gap-31xl);
  font-size: 33.26px;
  color: var(--studio-darkmode-popuplabels-5e6a86);
  font-family: var(--components-button-large);
}
.logoslidesectionChild {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.landingpage,
.logoslidesection {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.logoslidesection {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  height: 150px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-181xl);
}
.landingpage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  padding: 0 0 var(--padding-xl);
  text-align: left;
  font-size: 48px;
  color: var(--color-dimgray-200);
  font-family: var(--font-changa-one);
}
@media screen and (max-width: 1200px) {
  .herocityimage {
    padding-right: 0;
    box-sizing: border-box;
  }
  .herosectionnew {
    padding: var(--padding-61xl);
    box-sizing: border-box;
  }
  .youtubeviewer {
    padding-right: 0;
    box-sizing: border-box;
  }
  .videosection {
    padding: var(--padding-61xl);
    box-sizing: border-box;
  }
  .card1 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .featuredDestinationCopywriti {
    flex: unset;
    align-self: stretch;
  }
  .cityimageIcon {
    width: 100% !important;
    height: auto !important;
  }
  .herocityimage {
    padding-right: 0;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .herosectionnew {
    flex-direction: column;
    padding: var(--padding-41xl) var(--padding-21xl);
    box-sizing: border-box;
  }
  .youtubeviewer {
    padding-right: 0;
    box-sizing: border-box;
    height: 320px;
  }
  .videosection,
  .youtubedescription {
    padding: var(--padding-5xl) 0;
    box-sizing: border-box;
  }
  .videosection {
    flex-direction: column;
    padding: var(--padding-41xl) var(--padding-21xl);
  }
  .card1 {
    flex: unset;
    align-self: stretch;
  }
  .mainsection {
    flex-direction: column;
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .acectitle {
    font-size: var(--font-size-11xl);
  }
  .featuredDestinationTitle {
    font-size: var(--font-size-17xl);
  }
  .chineseNewYear {
    font-size: var(--font-size-11xl);
  }
  .cardDescription1Container,
  .cardDescription2Container {
    font-size: var(--font-size-3xl);
  }
  .mainsection {
    padding: var(--padding-11xl) var(--padding-17xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 431px) {
  .acectitle {
    font-size: var(--font-size-11xl);
  }
  .acecdesc {
    font-size: var(--font-size-xs);
  }
  .featuredDestinationTitle {
    font-size: var(--font-size-11xl);
  }
  .chineseNewYear {
    font-size: var(--font-size-9xl);
  }
  .cardDescription1Container,
  .cardDescription2Container {
    font-family: var(--components-button-large);
    font-size: var(--font-size-xs);
  }
  .logoslidesection {
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}
