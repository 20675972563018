.eventtitle {
  align-self: stretch;
  position: relative;
  line-height: 54px;
  font-weight: 600;
}
.posteddate {
  position: relative;
  font-size: var(--font-size-5xl);
  line-height: 40px;
  font-weight: 500;
}
.eventtitlepostdate {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.organizer {
  align-self: stretch;
  position: relative;
  line-height: 27px;
  display: inline-block;
  height: 30px;
  flex-shrink: 0;
}
.pmJohn {
  margin: 0;
}
.pm1115Container {
  position: relative;
  line-height: 27px;
  color: var(--primary-text);
}
.agenda,
.pm1115Container,
.time1 {
  align-self: stretch;
}
.agenda {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-lg);
  color: var(--additional-1);
}
.time1 {
  position: relative;
  letter-spacing: 0.02em;
}
.timeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.div {
  position: relative;
  line-height: 34px;
  color: var(--color-gray-200);
  text-align: right;
}
.time {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.eventcontentpage,
.eventtimelocation {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.eventtimelocation {
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
  padding: var(--padding-11xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
}
.eventcontentpage {
  border-radius: var(--br-5xs);
  background-color: var(--color-aliceblue-200);
  padding: 0 var(--padding-281xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.event2detailpage {
  position: relative;
  background-color: var(--primary-contrast);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-30xl);
  color: var(--primary-and-header-color);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 960px) {
  .eventtimelocation {
    padding: var(--padding-5xl) 0;
    box-sizing: border-box;
  }
  .eventcontentpage {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
