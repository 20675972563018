.firstName {
  align-self: stretch;
  position: relative;
  line-height: 34px;
  font-weight: 600;
}
.firstnamelabel,
.input {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input {
  border: 1px solid var(--studio-lightmode-midseparator-dee3eb);
  font-family: var(--components-button-large);
  font-size: var(--font-size-xl);
  background-color: var(--primary-contrast);
  border-radius: var(--br-9xs);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  line-height: 30px;
  outline: 0;
}
.firstname,
.loginframe,
.namesection,
.phonenumberframe {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mid);
}
.firstname {
  flex: 1;
  flex-direction: column;
}
.loginframe,
.namesection,
.phonenumberframe {
  flex-direction: row;
}
.loginframe,
.phonenumberframe {
  flex-direction: column;
}
.loginframe {
  border-radius: var(--br-xs);
  background-color: var(--primary-contrast);
  padding: var(--padding-xl) var(--padding-31xl);
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 430px) {
  .firstname {
    flex: unset;
    align-self: stretch;
  }
  .namesection {
    flex-direction: column;
  }
  .loginframe {
    padding: var(--padding-3xs) var(--padding-3xs) var(--padding-xl);
    box-sizing: border-box;
  }
}
