.timeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pm {
  position: relative;
  line-height: 34px;
  color: var(--color-gray-200);
  text-align: right;
}
.time {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.gasSouthTheater {
  margin: 0;
}
.host {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
}
.eventtimelocation {
  align-self: stretch;
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl);
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 960px) {
  .eventtimelocation {
    padding: var(--padding-5xl) 0;
    box-sizing: border-box;
  }
}
