.events,
.viewAllEvents {
  position: relative;
  letter-spacing: 0.04em;
}
.events {
  flex: 1;
  text-transform: capitalize;
}
.viewAllEvents {
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--components-button-large);
  color: var(--color-cornflowerblue);
  text-align: right;
  display: inline-block;
  width: 140.7px;
  flex-shrink: 0;
}
.arrowRightIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.viewalleventbutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--primary-contrast);
  border-radius: var(--br-12xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.eventsheader {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.eventthumbnailIcon {
  border-radius: 11.84px;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}
.eventthumbnailIcon,
.sept {
  align-self: stretch;
  position: relative;
}
.eventdate {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.eventdescription,
.eventtitle {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.eventdescription {
  font-size: var(--font-size-sm);
  display: inline-block;
  height: 39.8px;
  flex-shrink: 0;
}
.eventcard,
.eventdesc,
.eventdescriptionsection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.eventdesc {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-4xs);
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
}
.eventcard,
.eventdescriptionsection {
  align-self: stretch;
  flex-direction: row;
}
.eventcard {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
}
.b,
.eventthumbnailIcon1 {
  align-self: stretch;
  position: relative;
}
.eventthumbnailIcon1 {
  border-radius: 11.84px;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
}
.b {
  font-size: var(--font-size-17xl);
  color: var(--color-black);
}
.eventdate1 {
  width: 102px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.eventdescription1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 150%;
  color: var(--color-dimgray-100);
  display: inline-block;
  height: 39.8px;
  flex-shrink: 0;
}
.eventcard1 {
  flex: 1;
  flex-direction: column;
  gap: 28.64px;
  cursor: pointer;
}
.eventcard1,
.eventcard2,
.eventsframe,
.eventssection {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.eventcard2 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
  cursor: pointer;
}
.eventsframe,
.eventssection {
  flex-direction: row;
  gap: var(--gap-31xl);
  font-size: var(--font-size-xl);
  color: var(--color-mediumblue);
  font-family: var(--font-dm-sans);
}
.eventssection {
  background-color: var(--primary-contrast);
  box-shadow: 0 0 46.9px rgba(0, 0, 0, 0.03);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-11xl) var(--padding-21xl);
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-darkslategray-300);
  font-family: var(--components-button-large);
}
@media screen and (max-width: 1200px) {
  .eventsheader {
    align-items: center;
    justify-content: flex-start;
  }
  .eventcard,
  .eventcard1,
  .eventcard2 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .viewalleventbutton {
    display: none;
  }
  .eventcard,
  .eventcard1,
  .eventcard2 {
    flex: unset;
    align-self: stretch;
  }
  .eventsframe {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .events {
    font-size: var(--font-size-3xl);
  }
  .eventssection {
    padding: var(--padding-11xl) var(--padding-17xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 431px) {
  .eventsheader {
    align-items: center;
    justify-content: flex-start;
  }
}
