.theAtlantaChinese {
  align-self: stretch;
  position: relative;
  line-height: 36px;
}
.establishedIn2015 {
  margin: 0;
}
.establishedIn2015Container {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--components-button-large);
  color: var(--color-slategray-100);
}
.missionsection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.subscriptionForm {
  align-self: stretch;
  position: relative;
  line-height: 34px;
  font-weight: 600;
}
.formText {
  flex-direction: column;
  align-items: flex-start;
}
.form,
.formFields,
.formText,
.input {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.input {
  border: 1px solid var(--studio-lightmode-midseparator-dee3eb);
  font-family: var(--components-button-large);
  font-size: var(--font-size-xl);
  background-color: var(--primary-contrast);
  border-radius: var(--br-9xs);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  line-height: 30px;
  outline: 0;
}
.form,
.formFields {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.form {
  border-radius: var(--br-xs);
  background-color: var(--primary-contrast);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
  border: 1px solid var(--color-whitesmoke-200);
  padding: var(--padding-9xl) var(--padding-11xl);
  gap: var(--gap-mid);
  font-size: var(--font-size-3xl);
  font-family: var(--font-poppins);
}
.missionsectionParent {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-21xl);
  gap: var(--gap-xl);
}
.aboutussection,
.memberpage,
.missionsectionParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutussection {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-2xs);
}
.memberpage {
  position: relative;
  width: 100%;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-changa-one);
}
@media screen and (max-width: 420px) {
  .missionsectionParent {
    flex: unset;
    align-self: stretch;
  }
  .aboutussection {
    flex-direction: column;
  }
}
