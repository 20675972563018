@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rowdies:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --components-button-large: Roboto;
  --font-rowdies: Rowdies;
  --font-poppins: Poppins;
  --font-changa-one: "Changa One";
  --font-source-sans-pro: "Source Sans Pro";
  --font-dm-sans: "DM Sans";

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-5xl: 24px;
  --font-size-xl: 20px;
  --font-size-3xl: 22px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-11xl: 30px;
  --font-size-20xl: 39px;
  --font-size-17xl: 36px;
  --components-button-large-size: 15px;
  --font-size-30xl: 49px;
  --font-size-16xl: 35px;
  --font-size-25xl: 44px;
  --font-size-xs: 12px;
  --font-size-14xl-3: 33.3px;
  --font-size-9xl: 28px;

  /* Colors */
  --primary-contrast: #fff;
  --color-lightslategray: #939eb4;
  --color-black: #000;
  --color-aliceblue-100: #ecf7ff;
  --color-aliceblue-200: #dceaf5;
  --color-whitesmoke-100: #eee;
  --color-whitesmoke-200: #eaeaea;
  --studio-lightmode-midseparator-dee3eb: #dee3eb;
  --primary-and-header-color: #405363;
  --additional-1: #4b4b4d;
  --color-darkslategray-100: #434343;
  --color-darkslategray-200: #424242;
  --color-darkslategray-300: #303030;
  --color-dimgray-100: #6a6a6a;
  --color-dimgray-200: #495367;
  --color-blueviolet: #8a53ff;
  --studio-darkmode-normalsolid-icons-96a2be: #96a2be;
  --studio-darkmode-popuplabels-5e6a86: #5e6a86;
  --color-slategray-100: #576074;
  --color-silver: #c1c5c8;
  --color-gray-100: #f8fbfd;
  --primary-text: #74777b;
  --black: #191919;
  --color-gray-200: #06162a;
  --color-gray-300: #000224;
  --color-gray-400: rgba(255, 255, 255, 0.8);
  --color-darkslateblue: #003882;
  --color-mediumblue: #3d37f1;
  --color-cornflowerblue: #1262ae;

  /* Gaps */
  --gap-31xl: 50px;
  --gap-0: 0px;
  --gap-8xs: 5px;
  --gap-10xs: 3px;
  --gap-2xl: 21px;
  --gap-mid: 17px;
  --gap-lg: 18px;
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-mini: 15px;
  --gap-5xl: 24px;
  --gap-7xs: 6px;
  --gap-11xl: 30px;
  --gap-4xs: 9px;
  --gap-9xl-6: 28.6px;
  --gap-5xs-4: 7.4px;

  /* Paddings */
  --padding-5xl: 24px;
  --padding-11xl: 30px;
  --padding-61xl: 80px;
  --padding-10xl: 29px;
  --padding-21xl: 40px;
  --padding-3xs: 10px;
  --padding-9xl: 28px;
  --padding-281xl: 300px;
  --padding-xl: 20px;
  --padding-31xl: 50px;
  --padding-lg: 18px;
  --padding-11xs: 2px;
  --padding-100xl: 119px;
  --padding-8xs: 5px;
  --padding-41xl: 60px;
  --padding-181xl: 200px;
  --padding-5xs: 8px;
  --padding-81xl: 100px;
  --padding-smi: 13px;
  --padding-2xs: 11px;
  --padding-17xl: 36px;

  /* border radiuses */
  --br-xs: 12px;
  --br-9xs: 4px;
  --br-xs-8: 11.8px;
  --br-5xs: 8px;
  --br-8xs: 5px;
  --br-12xl: 31px;
}
