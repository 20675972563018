.subscriptionForm {
  align-self: stretch;
  position: relative;
  line-height: 34px;
  font-weight: 600;
}
.formText {
  flex-direction: column;
  align-items: flex-start;
}
.form,
.formFields,
.formText,
.input {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.input {
  border: 1px solid var(--studio-lightmode-midseparator-dee3eb);
  font-family: var(--font-roboto);
  font-size: var(--font-size-xl);
  background-color: var(--color-white);
  border-radius: var(--br-9xs);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  line-height: 30px;
  outline: 0;
}
.form,
.formFields {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.form {
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
  border: 1px solid var(--color-whitesmoke-200);
  padding: var(--padding-9xl) var(--padding-11xl);
  gap: var(--gap-mid);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-poppins);
}
